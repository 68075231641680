import ErrorPage from "@/components/modules/ErrorPage/ErrorPage"

function Custom404() {
    return (
        <ErrorPage status={404} />
    )
}

Custom404.Nav = <></>

export default Custom404;

