import { motion } from 'framer-motion';

import styles from './ErrorPage.module.scss';

const ErrorPage = ({statusCode}) => {

    const slideUp = {
        hidden: {
            y: 30,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
        },
        exitHidden: {
            y: 30,
            opacity: 0,
        }
    }

    function getErrorContent(){

        switch (statusCode){
            case "404":
                return <>
                        <h1>
                            4
                            <svg height={120} viewBox="0 0 152 155" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M151.926 83.16C150.937 59.6424 139.75 39.949 122.797 28.2021C130.58 39.5488 134.957 52.8838 135.413 66.6357C137.081 106.249 110.394 135.664 71.1508 137.463C54.7695 138.225 40.1754 134.091 28.5 126.192C40.9498 144.504 62.1683 155.154 87.6636 153.987C126.919 152.176 153.594 122.785 151.926 83.16Z" fill="#5694EF" style={{animationDelay: ".33s"}} />
                                <path d="M81.0876 16.6204C95.8886 15.729 110.564 19.8044 122.785 28.2004C110.038 9.901 88.8433 -1.1549 64.5632 0.0960397C26.4394 2.0618 -1.4984 33.0732 0.0622917 71.7927C1.01539 95.62 11.678 114.801 28.4882 126.191C21.4115 115.778 17.1583 102.899 16.5745 88.305C15.0376 49.5974 42.94 18.5861 81.0876 16.6204Z" fill="#FFC100" style={{animationDelay: ".66s"}} />
                                <path d="M71.1533 137.461C110.397 135.662 137.084 106.247 135.416 66.6341C134.96 52.8822 130.582 39.5471 122.799 28.2005C110.577 19.8045 95.9024 15.7291 81.1013 16.6204C42.9775 18.5862 15.0517 49.5975 16.6005 88.3051C17.1842 102.899 21.4374 115.778 28.5141 126.191C40.1538 134.089 54.772 138.271 71.1533 137.461Z" fill="#FD949E" style={{animationDelay: "1s"}} />
                            </svg>
                            4
                        </h1>
                        <h2>We can&#39;t seem to find the page you are looking for...</h2>
                    </>

            case "500":
                return <>
                    <h1>
                        5
                        <svg height={120} viewBox="0 0 152 155" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M151.926 83.16C150.937 59.6424 139.75 39.949 122.797 28.2021C130.58 39.5488 134.957 52.8838 135.413 66.6357C137.081 106.249 110.394 135.664 71.1508 137.463C54.7695 138.225 40.1754 134.091 28.5 126.192C40.9498 144.504 62.1683 155.154 87.6636 153.987C126.919 152.176 153.594 122.785 151.926 83.16Z" fill="#5694EF" style={{animationDelay: ".33s"}} />
                            <path d="M81.0876 16.6204C95.8886 15.729 110.564 19.8044 122.785 28.2004C110.038 9.901 88.8433 -1.1549 64.5632 0.0960397C26.4394 2.0618 -1.4984 33.0732 0.0622917 71.7927C1.01539 95.62 11.678 114.801 28.4882 126.191C21.4115 115.778 17.1583 102.899 16.5745 88.305C15.0376 49.5974 42.94 18.5861 81.0876 16.6204Z" fill="#FFC100" style={{animationDelay: ".66s"}} />
                            <path d="M71.1533 137.461C110.397 135.662 137.084 106.247 135.416 66.6341C134.96 52.8822 130.582 39.5471 122.799 28.2005C110.577 19.8045 95.9024 15.7291 81.1013 16.6204C42.9775 18.5862 15.0517 49.5975 16.6005 88.3051C17.1842 102.899 21.4374 115.778 28.5141 126.191C40.1538 134.089 54.772 138.271 71.1533 137.461Z" fill="#FD949E" style={{animationDelay: "1s"}} />
                        </svg>
                        0
                    </h1>
                    <h2>Our server could not fulfill your request...</h2>
                </>

            default:
                return <>
                        <h1>
                            4
                            <svg height={120} viewBox="0 0 152 155" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M151.926 83.16C150.937 59.6424 139.75 39.949 122.797 28.2021C130.58 39.5488 134.957 52.8838 135.413 66.6357C137.081 106.249 110.394 135.664 71.1508 137.463C54.7695 138.225 40.1754 134.091 28.5 126.192C40.9498 144.504 62.1683 155.154 87.6636 153.987C126.919 152.176 153.594 122.785 151.926 83.16Z" fill="#5694EF" style={{animationDelay: ".33s"}} />
                                <path d="M81.0876 16.6204C95.8886 15.729 110.564 19.8044 122.785 28.2004C110.038 9.901 88.8433 -1.1549 64.5632 0.0960397C26.4394 2.0618 -1.4984 33.0732 0.0622917 71.7927C1.01539 95.62 11.678 114.801 28.4882 126.191C21.4115 115.778 17.1583 102.899 16.5745 88.305C15.0376 49.5974 42.94 18.5861 81.0876 16.6204Z" fill="#FFC100" style={{animationDelay: ".66s"}} />
                                <path d="M71.1533 137.461C110.397 135.662 137.084 106.247 135.416 66.6341C134.96 52.8822 130.582 39.5471 122.799 28.2005C110.577 19.8045 95.9024 15.7291 81.1013 16.6204C42.9775 18.5862 15.0517 49.5975 16.6005 88.3051C17.1842 102.899 21.4374 115.778 28.5141 126.191C40.1538 134.089 54.772 138.271 71.1533 137.461Z" fill="#FD949E" style={{animationDelay: "1s"}} />
                            </svg>
                            4
                        </h1>
                        <h2>We can&#39;t seem to find the page you are looking for...</h2>
                    </>

        }

    }



    return ( 
        <section className={styles.error}>

            <div className={styles.error__container}>

                <motion.div className={`${styles.error__mainTexts}`} variants={slideUp} initial="hidden" animate="visible" exit="exitHidden" transition={{duration: .35, delay: .2}}>
                    {getErrorContent()}
                </motion.div>

                <motion.div className={`${styles.error__cta}`} onClick={()=>window.location.href="/watch"} variants={slideUp} initial="hidden" animate="visible" exit="exitHidden" transition={{duration: .35, delay: .4}}>
                    Come home
                </motion.div>

            </div>
        </section>
     );
}
 
export default ErrorPage;